
@font-face {
    font-family: "Lato";
    src: url("../fonts/Lato-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/Lato-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}



@font-face {
    font-family: "UA-Brand";
    src: url("../fonts/UA-BRAND.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

:root {
    --color-white: #fff;
    --color-black: #000;
    --color-text: #595858;
    --color-footer: #B6B6B6;
    --color-primary: #3366EA;
    --color-error: #E1473D;
    --Lato:'Lato', sans-serif;
    --UaBrand:'UA-Brand', sans-serif;
}

*, *:before,
*:after {
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

*, body, html {
    -webkit-text-size-adjust: none;
    -webkit-appearance: none;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button {
    border: none;
}

mark {
    -webkit-text-fill-color: initial;
    text-fill-color: transparent;
    background: none;
}
.img{
    display: block;
}
.img.contain img {
    -o-object-fit: contain;
    object-fit: contain;
}

.img > img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
}

button {
    background: transparent;
}

h1 {
    margin: 0;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;
}
@media only screen and (max-width: 1680px) {
    html {
        font-size: 8.75px;
    }
}

@media only screen and (max-width: 1440px) {
    html {
        font-size: 7.5px;
    }
}
@media only screen and (max-width: 1366px) {
    html {
        font-size: 7.11px;
    }
}
@media only screen and (max-width: 1280px) {
    html {
        font-size: 6.66px;
    }
}
@media only screen and (max-width: 1170px) {
    html {
        font-size: 6.1px;
    }
}

@media only screen and (max-width: 1024px) {
    html {
        font-size: 5.33px;
    }
}

@media only screen and (max-width: 890px) {
    html {
        font-size: 4.63px;
    }
}

@media only screen and (max-width: 768px) {
    html {
        font-size: 4px;
    }
}

@media only screen and (max-width: 666px) {
    html {
        font-size: 10px;
    }
}